import { ACCION_EXITOSA } from "../../constants/cons";
import { URL_BASE } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { post } from "../../utils/fetch";
import { notification } from "./notificationActions";

export const startOpAutorizacionMasivo = (body) => {
  return async (dispatch) => {
    const url = `${URL_BASE}/api/OpAutorizacionMasivo`;
    const result = await post(url, body);

    //
    if (result.code === 0) {
      dispatch(
        notification(composeSuccessNotification(result.data))
      );
    } else
      dispatch(
        notification(
          composeErrorNotification(
            "startOpAutorizacionMasivo",
            `${result.error}`
          )
        )
      );
  };
};
