import { Alert, Button, Checkbox, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LabelText from "../../../components/input/LabelText";
import {
  changeUsuarioForm,
  PostEliminar,
  startPostUsuario,
} from "../../../context/actions/usuariosActions";
import { Mensaje } from "../../../layout/Mensaje";

export const UsuarioForma = () => {
  const {
    nombre,
    email,
    password,
    password2,
    esCabina,
    esBackoffice,
    usuarioID,
    esProveedor,
  } = useSelector((state) => state.usuariosReducer.usuario);

  const { mensaje } = useSelector((state) => state.usuariosReducer);
  const dispatch = useDispatch();

  const onChange = ({ target }) => {
    const body = {
      [target.name]: target.value,
    };
    dispatch(changeUsuarioForm(body));
  };

  const onCheckedChange = ({ target }) => {
    const body = {
      [target.name]: target.checked,
    };
    dispatch(changeUsuarioForm(body));
  };

  /////////////////////

  const handleCrear = () => dispatch(startPostUsuario());
  ///////////////////////
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <LabelText
            label="Nombre"
            name="nombre"
            value={nombre}
            onChange={onChange}
          />
        </Col>
        <Col span={12}>
          <LabelText
            label="Email"
            name="email"
            value={email}
            onChange={onChange}
          />
        </Col>
        <Col span={12}>
          <LabelText
            label="Contraseña"
            name="password"
            value={password}
            onChange={onChange}
          />
        </Col>
        <Col span={12}>
          <LabelText
            label="Confirmar Contarseña"
            name="password2"
            onChange={onChange}
            value={password2}
          />
        </Col>
        <Col span={8}>
          <Checkbox
            name="esCabina"
            onChange={onCheckedChange}
            checked={esCabina}
          >
            Cabina
          </Checkbox>
        </Col>{" "}
        <Col span={8}>
          <Checkbox
            name="esBackoffice"
            onChange={onCheckedChange}
            checked={esBackoffice}
          >
            Backoffice
          </Checkbox>
        </Col>
        <Col span={8}>
    
        </Col>
        <Button type="primary" onClick={handleCrear}>
          {usuarioID !== undefined && usuarioID !== "" ? "Actualizar" : "Crear"}
        </Button>
        {usuarioID !== undefined && usuarioID !== "" && (
          <Button
            type="danger"
            onClick={() => dispatch(PostEliminar(usuarioID))}
            style={{ marginLeft: 10 }}
          >
            Eliminar
          </Button>
        )}
      </Row>

      <Mensaje componente={mensaje} />
    </>
  );
};
