import { useState } from "react";
import { useDispatch } from "react-redux";
import { startOpAutorizacionMasivo } from "../../../../context/actions/opAutorizaMasivo";

export const useForm = () => {
  const dispatch = useDispatch();
  const [body, setBody] = useState({
    usuarioAutorizaID: "8de97c53-6ab1-4c30-8d0c-b1b657945cd9",
    nivel: 1,
  });
  const change = (ele, val) => setBody({ ...body, [ele]: val });

  const submit = () => {
    let ordenes = body.ordenesPagoID.replace(/\n/g, ",");
    ordenes = quitaEspacios(ordenes).split(",");
    const final = {
      ...body,
      ordenesPagoID: ordenes,
    };
    console.log(final);
    dispatch(startOpAutorizacionMasivo(final));
  };

  const disabled = () => {
    if (body.usuarioAutorizaID && body.nivel && body.ordenesPagoID)
      return false;
    return true;
  };

  const quitaEspacios = (cadena) => {
    let nuevaCadena = "";
    for (let i = 0; i < cadena.length; i++) {
      if (cadena[i] !== " ") nuevaCadena += cadena[i];
    }
    return nuevaCadena;
  };

  return {
    body,
    change,
    submit,
    disabled,
  };
};
