import { Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { correosPuedeAutorizarMasivo } from "../../../constants/cons";
import { useHistory } from "react-router-dom/";

export const BotonAutorizarMasivo = () => {
  const hisotory = useHistory();
  const { email } = useSelector((state) => state.userReducer.user);

  if (!correosPuedeAutorizarMasivo.includes(email)) return <></>;

  return (
    <Button
      onClick={() => hisotory.push("/ordenesPago/AutorizarMasivo")}
      type="link"
    >
      Autorizar Masivo
    </Button>
  );
};
