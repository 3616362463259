import LabelSelect from "../../../../components/select/LabelSelect";
import {
  correosPuedeAutorizarMasivo,
  opcionesUsuriosUtorizarMasivo,
} from "../../../../constants/cons";
import { Button, Col, Input, Row } from "antd";
import { useForm } from "./useForm";
import CardPage from "../../../../layout/CardPage";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export const AutorizarMasivo = () => {
  const hisotory = useHistory();
  const { email } = useSelector((state) => state.userReducer.user);

  const { body, change, submit, disabled } = useForm();

  if (!correosPuedeAutorizarMasivo.includes(email))
    hisotory.push("/ordenespago");
  //
  return (
    <CardPage titulo="Autorización Masiva">
      <Row gutter={[10, 10]}>
        <Col span={8}>
          <LabelSelect
            value={body.usuarioAutorizaID}
            label="Usuario Autorizador"
            opciones={opcionesUsuriosUtorizarMasivo}
            onChange={(val) => change("usuarioAutorizaID", val)}
          />
        </Col>
        <Col span={8}>
          <LabelSelect
            value={body.nivel}
            label="Autorización Nivel"
            opciones={[
              { texto: "Nivel 1", valor: 1 },
              { texto: "Nivel 2", valor: 2 },
            ]}
            onChange={(val) => change("nivel", val)}
          />
        </Col>
        <Col span={16}>
          <Input.TextArea
            placeholder="órdenes de pago..."
            onChange={({ target }) => change("ordenesPagoID", target.value)}
            style={{ height: 400 }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={16}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            disabled={disabled()}
            onClick={submit}
          >
            Autorizar Órdenes de Pago
          </Button>
        </Col>
      </Row>
    </CardPage>
  );
};
